import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import SearchTemplate from "../../../templates/search-results-template";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import { milesToMeter, isAreaMatch, getLatLan } from "../../../components/utils/property-search"
import SeoResult from "../../../components/seoResult";
import _ from "lodash";
import $ from "jquery"

const initConf = {
    hitsPerPage: 20,
    aroundLatLng : "51.191633,-0.4061108",
    getRankingInfo : true,
}

const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

class Search extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            selectedpromotions: [],
            building: "",
            sortBy: "",
            searchType: "lettings",
            department: "residential",
            popular: "",
            page: "",
            mapView: false,
            radius: 3,
            searchState: {},
            algoliaConfig: initConf,
            areaList: props.data?.allArea?.nodes,
            searchCounty: ""
        }
    }
    changeStateAndUrl = (searchState) => {
        let urls = [];
        var localQuery = localStorage.getItem('searchValue')
        if (localQuery !== null) {
            searchState.query = localQuery;
        }  
        if (typeof searchState.query !== "undefined") {            
            if (searchState.query !== "") {
                urls.push(`in-${searchState.query}`)
                this.setState({ query: searchState.query })
            } else {
                urls.push(``)
                this.setState({ query: searchState.query })
            }
        } else {
            if (this.state.query !== "") {
                var queryParam =typeof this.state.query !=  "undefined" ? this.state.query : "kent" ;
                urls.push(`in-${queryParam }`)
                this.setState({ query: queryParam})
            } else {
                urls.push(``)
                this.setState({ query: this.state.query })
            }
        }

        // create Promotions URL
        if (typeof searchState.refinementList !== "undefined") {
            if (searchState.refinementList.property_promotions) {
                var promo = []

                searchState.refinementList.property_promotions.forEach((item, index) => {
                    this.props.data?.allPromotion.nodes.forEach((node, index) => {
                        if (item) {
                            if (String(node.promo_id) == item) {
                                var pro_title = _.lowerCase(node.title)
                                promo.push(pro_title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-'))
                            }
                        }
                    })
                })

                urls.push(`with-${promo.join('-and-')}`)
                this.setState({ property_promotions: searchState.refinementList.property_promotions })
            }

        } else if (this.state.selectedpromotions.length > 0) {
            // console.log("zzzx",this.state.selectedpromotions)
            urls.push(`with-${this.state.selectedpromotions}`)
        }
        // create Promotions URL

        // create building URL
        if (typeof searchState.menu !== "undefined") {
            if (searchState.menu.searchBuilding) {
                urls.push(`type-${searchState.menu.searchBuilding}`)
                this.setState({ building: searchState.menu.searchBuilding })
            } else {
                this.setState({ building: "" })
            }
            if (searchState.menu.accomadation_summary) {
                this.setState({ popular: searchState.menu.accomadation_summary })
            }
        } else if (this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }

        // create bedroom URL
        if (searchState.range && searchState.range.bedroom) {
            let bedroom = searchState.range.bedroom.min;
            if (bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else {
                this.setState({ bedrooms: "" })
            }
        } else if (this.state.bedrooms !== "") {
            urls.push(`${this.state.bedrooms}-and-more-bedrooms`)
        }

        // create Price min and Max URL
        if (typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
            let price = searchState.range.price;
            let min = price.min;
            let max = price.max;
            if (min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else if (this.state.min !== "") {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else if (this.state.max !== "") {
                    this.setState({ max: "" })
                }
            }
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if (min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: "" })
                }
            }
        }

        // create radius URL   
        var isMatched = false
        if (!_.isEmpty(searchState.query) && this.state.radius != "") {
            let selectedArea = isAreaMatch(this.state.areaList, searchState.query)
            if (!_.isEmpty(selectedArea) && this.state.radius != "") {
                isMatched = true;
                let algoliaConfig = { ...this.state.algoliaConfig }
                algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                algoliaConfig.getRankingInfo = true;
                this.setState({ algoliaConfig })
                urls.push(`radius-${this.state.radius}-miles`)
            } else {
                isMatched = false;
                this.setState({ algoliaConfig: initConf })
            }
        } 
        // else if (!_.isEmpty(this.state.query) && this.state.radius != "") {
        //     let selectedArea = isAreaMatch(this.state.areaList, this.state.query)
        //     if (!_.isEmpty(selectedArea) && this.state.radius != "") {
        //         isMatched = true;
        //         let algoliaConfig = { ...this.state.algoliaConfig }
        //         algoliaConfig.aroundLatLng = getLatLan(selectedArea);
        //         algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
        //         this.setState({ algoliaConfig })
        //         urls.push(`radius-${this.state.radius}-miles`)
        //     } else {
        //         isMatched = false;
        //         this.setState({ algoliaConfig: initConf })
        //     }
        // } 
        else {
            isMatched = false;
            this.setState({ algoliaConfig: initConf })
        }
        if (localQuery !== null && isMatched) {
            searchState.query = '';
        } else {
            localStorage.removeItem('searchValue');
            localStorage.removeItem('searchValueFull');
        }

        
        // Sort path
        if (typeof searchState.sortBy !== "undefined") {
            if (searchState.sortBy) {
                if (searchState.sortBy === index_name + "_price_asc") {
                    urls.push("sortby-price-asc")
                }
                if (searchState.sortBy === index_name + "_price_desc") {
                    urls.push("sortby-price-desc")
                }
                if (searchState.sortBy === index_name + "_most_recent") {
                    urls.push("sortby-most-recent")
                }
                if (searchState.sortBy === index_name + "_yield_desc") {
                    urls.push("higest-yield")
                }
                if (searchState.sortBy === index_name + "_yield_asc") {
                    urls.push("lowest-yield")
                }
            }
        } else if (this.state.sortBy !== "") {
            urls.push(`${this.state.sortBy}`)
        }
        // Sort path

        if (this.state.page !== "") {
            urls.push(`page-${this.state.page}`)
            searchState.page = this.state.page;
            this.setState({ page: this.state.page })
            setTimeout(() => {
                this.setState({ page: "" })                
            }, 200);

        } else if (this.state.page == "" && searchState.page !== "" && searchState.page > 1) {
            urls.push(`page-${searchState.page}`)
        }



        if (window) {
            this.setState({ searchCounty: window.localStorage.getItem('searchCounty') })
        }

        let url = urls.join('/');
        let newRoute = '/property/to-rent/' + url;
        navigate(newRoute)
    }
    componentDidMount() {
        // if (window)
        //     window.scroll({
        //         top: -100,
        //         behavior: 'smooth'
        //     });

        const { params } = this.props;
        const searchParams = params['*'].split('/');

        
        if (window) {
            this.setState({ searchCounty: window.localStorage.setItem("searchCounty","") })
        }


        var queryParam = "";
        searchParams.forEach(param => {
            // if (param.includes("in-") && !param.includes("in-kent")) {
            if (param.includes("in-")) {
                // if(param.includes("in-")) {
                queryParam = param.substring(3);              
                this.setState({ query: param.substring(3) })
            }
            if (param.includes("under-")) {
                this.setState({ max: param.substring(6) })
            }
            if ([param].indexOf("over-") >= 0) {
                this.setState({ min: param.substring(5) })
            }
            if (param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0] })
            }
            if (param.includes("type-")) {
                this.setState({ building: param.split("-")[1] })
            }
            if (param.indexOf("page-") >= 0) {
                this.setState({ page: param.replace("page-", "") })
            }
            if (param.indexOf("sortby-") >= 0) {
                this.setState({ sortBy: param })
            }
            if (param.includes("with-")) {
                var indusArrfilt = param.replace("with-", "")
                var industryVal = indusArrfilt.split("-and-")
                let mapValues = this.props.data?.allPromotion.nodes.map((node, index) => {
                    var pro_title = _.lowerCase(node.title)
                    node["url"] = pro_title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-')
                    return node;
                })

                this.setState({ property_promotions: mapValues.filter(c => industryVal.includes(c.url)).map(c => String(c.promo_id)) })
            }
            if (param.includes("radius-")) {
                let radius = param.split("-")[1];
                if (!_.isEmpty(queryParam) && radius != "") {
                    let selectedArea = isAreaMatch(this.state.areaList, queryParam)
                    if (!_.isEmpty(selectedArea) && radius != "") {
                        let algoliaConfig = { ...this.state.algoliaConfig }
                        algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                        algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                        algoliaConfig.getRankingInfo = true;

                        this.setState({ radius, algoliaConfig }, () => {
                            this.changeStateAndUrl({ query: queryParam })
                        })
                    } else {
                        this.setState({ algoliaConfig: initConf })
                    }
                } else {
                    this.setState({ algoliaConfig: initConf })
                }
            }
            if (param.includes("between-")) {
                this.setState({
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });

        if (sessionStorage.getItem('MainView') == 'Grid') {
            this.setState({ mapView: true })
        }


        $(document).mouseup(function (e) {
            var container = $(".dropdown-exp");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".dropdown-exp").removeClass("active");
            }
        });

    }
    // componentDidUpdate(prevProps) {
    //     // console.log("ccc1", prevProps, this.props.location)
    //     if (prevProps.location.pathname !== this.props.location.pathname) {

    //         $(window).on('popstate', function (event) {
    //             localStorage.removeItem('searchValue');
    //             window.location.href = prevProps.location.pathname;
    //         });
    //         // this.setState({ backenable: true }, () => {
    //         //     this.componentDidMount()
    //         // })
    //     }
    // }
    changeToMapUrl = () => {
        this.setState({ mapView: true })
    }
    changeToListUrl = () => {
        this.setState({ mapView: false })
    }

    changeRadius = (radius) => {
        if (radius != "") {
            this.setState({ radius, query: this.state.searchState.query }, () => {
                // console.log('searchState sdsd', this.state.searchState)
                this.changeStateAndUrl(this.state.searchState)
            })
        } else {
            this.setState({ radius: 3 }, () => { this.changeStateAndUrl(this.state.searchState) });
        }
    }

    render() {
        var search_area = "";
        var price_range = "", property_type = "", bedrooms = "", promotion = "";
        var fullarea = typeof window !== 'undefined' && localStorage ? localStorage.getItem('searchValueFull') : ''
        if (this.props.params["*"]) {
            search_area = fullarea ? fullarea : this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in " + this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "";

            // price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-"))[0].split("-").join(" ") : "";
            price_range = this.props.params["*"].split("/").filter(c => [c].indexOf("over-") >= 0 || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => [c].indexOf("over-") >= 0 || c.includes("below-") || c.includes("between-"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";

            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1] : "";

            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";

            promotion = this.props.params["*"].split("/").filter(c => c.includes("with-")).length > 0 ?
                this.props.params["*"].split("/").filter(c => c.includes("with-"))[0].split("-").join(" ") : '';
        }

        var h1 = (price_range !== "" && property_type !== "" && bedrooms !== "") ?
            `${bedrooms} Bedroom ${property_type} to rent ${search_area} ${price_range}` :
            (property_type !== "" && bedrooms !== "") ?
                `${bedrooms} Bedroom ${property_type} to rent in ${search_area}` :
                (price_range !== "" && bedrooms !== "") ?
                    `${bedrooms} Bedroom properties to rent in ${search_area} ${price_range}` :
                    (price_range !== "" && property_type !== "") ?
                        `${property_type} to rent in ${search_area} ${price_range}` :
                        (bedrooms !== "") ?
                            `${bedrooms} Bedroom properties to rent in ${search_area}` :
                            (property_type !== "") ?
                                `${property_type} to rent in ${search_area}` :
                                (price_range !== "") ?
                                    `Properties to rent ${search_area} ${price_range}` :
                                    `Properties to rent ${search_area}`

        var desc =
            (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                `Explore ${bedrooms} bedroom ${property_type} to rent ${search_area} ${price_range} available with Wards. Contact one of our estate agents for assistance in finding your property ${search_area}.` :
                (property_type !== "" && bedrooms !== "") ?
                    `Find the latest ${property_type} to rent ${search_area} with more than ${bedrooms} bedrooms. Contact Wards estate agents and letting agents ${search_area}, to arrange a viewing.` :
                    (price_range !== "" && bedrooms !== "") ?
                        `Explore the list of property to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Wards and request a viewing for the property that interests you.` :
                        (price_range !== "" && property_type !== "") ?
                            `Check out the latest collection of ${property_type} to rent ${price_range} ${search_area} ${price_range} with Wards estate agents${search_area ? ' '+search_area+'.' : '.'}` :
                            (bedrooms !== "") ?
                                `Explore property to rent ${search_area} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right property${search_area ? ' '+search_area+'.' : '.'}` :
                                (property_type !== "") ?
                                    `Looking for a ${property_type} to rent in Kent? Wards estate agents are here to help.` :
                                    (price_range !== "") ?
                                        `Locate the right property to rent ${search_area} ${price_range} with Wards estate agents. Book an appointment with one of our property experts${search_area ? ' '+search_area+'.' : '.'}` :
                                        `Explore our latest collection of property to rent${search_area ? ' '+search_area+'.' : '.'} Contact Wards estate agents ${search_area} to find the right property for you.`



        return (
            <>
                <SeoResult
                    title={`${h1} ${promotion ? promotion : ''} `}
                    description={desc}
                    county={this.state.searchCounty}
                />
                <SearchTemplate
                    {...this.state}
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    changeRadius={this.changeRadius}
                    location={this.props.location}
                    search_area={search_area}
                    desc={`${desc}`}
                    bedrooms={bedrooms}
                    promotion={promotion}
                    h1={`${h1} ${promotion ? promotion : ''} `}
                    property_type={property_type}
                    search_type="rent"
                // mapView={sessionStorage.getItem('ResultsView') == 'gridview'}
                />
            </>
        )
    }
}

export default Search;

export const RentpageQuery = graphql`
query {
    allArea {
      nodes {
        id
        name
        latitude
        longitude
        districtCountyTown
      }
    }
    allPromotion {
        nodes {
          id
          name
          title
          promo_id
        }
      }
  }
`